/**
 * Created by gschulz on 11.07.16.
 */
var OrganisationWeatherSection = (function () {

    var _private = {};

    _private.form = $();

    _private.weatherSection = $();

    this.init = function (form) {
        _private.form           = form;
        _private.weatherSection = _private.form.find('div.section.weather');
        _private.initFormTable();
        return this;
    };

    _private.initFormTable = function () {
        _private.formTableClass = new FormTable();
        var list           = _private.weatherSection.find('div.weather-layers');
        _private.formTableClass.init(list);
        _private.weatherSection.find('a.add-weather-line').unbind('click.addLayer').on('click.addLayer',
            function () {
                var template = $.tmpl(_private.form.find('#weather-layer-template'), {});
                list.append(template);
                template.find('.initial-error').removeClass('initial-error');
                template.find('input,select').pVal(0).val('');
                _private.formTableClass.reorderList(list);
                new Organisation().init();
            }
        );
        _private.weatherSection.find('a.remove').unbind('click.removeLayer').on('click.removeLayer',
            function (event) {
                $(event.target).parents('div.form-table-line').remove();
                _private.formTableClass.reorderList(list);
            }
        );
    };



});